import OSS from 'ali-oss';
import { nanoid } from 'nanoid';

const file = function (){}
/**
 * 选择文件
 * @param {Object | undefined} options 
 * @returns {Promise | undefined}
 */
file.cloose = function(options = {}){
    return new Promise(resolve=>{
        let dom = document.createElement('input'); 
        dom.type = 'file';
        dom.accept = options.accept || '';
        dom.multiple = options.multiple || false;
        dom.onchange = function(){
            let files = dom.files;
            if(options.multiple){
                files.length > 0 && resolve(files);
            }else{
                let file = files[0];
                file && resolve(file);
            }
        }
        let event = new MouseEvent('click');
        dom.dispatchEvent(event);
    })
}
/**
 * oss上传
 * @param {Object} options 
 * @returns {Promise}
 */
file.ossUpload = function(options){
    if(!options || !options.file || !options.uploadOptions) return Promise.reject();
    let {
        file,
        uploadOptions,
        headers = {}
    } = options;
    headers = {
        'Cache-Control': 'public',
        'Content-Disposition': encodeURIComponent(file.name),
        ...headers
    }
    return new Promise((resolve, reject)=>{
        const client = new OSS({
            region: uploadOptions.Region,
            accessKeyId: uploadOptions.AccessKeyId,
            accessKeySecret: uploadOptions.AccessKeySecret,
            stsToken: uploadOptions.SecurityToken,
            bucket: uploadOptions.Bucket
        });
        let nameID = uploadOptions.ObjectNamePrefix + nanoid();
        client.put(nameID, file, {headers}).then(data=>{
            resolve(data);
        }).catch((err)=> {
            reject(err);
        });
    })
}

export default file;