<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data(){
    return{

    }
  }
}
</script>
<style>
@import '~@/assets/css/index.css';
@import '~@/assets/css/clear-el.css';
</style>
